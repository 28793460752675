import {memo} from 'react'
import {Navigation} from '../index'
import * as Styled from './HeaderStyles'

const Header = (props) => {
    const {navigationLinksData, mainPage} = props
    return (
        <Styled.HeaderContainer> 
            <Navigation 
                navigationLinksData={navigationLinksData}
                mainPage={mainPage}
            />
      
        </Styled.HeaderContainer>
    )
}

export default memo(Header)
