import styled from 'styled-components'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'
import {FlexContainer, SectionContainer} from '../../styles/StyledElements'


export const HeaderContainer = styled(SectionContainer)``

export const Wrapper = styled(FlexContainer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 40rem;

  ${ screen_breakpoints.xl } {
   max-width: 32rem;
  }

  ${ screen_breakpoints.xxl } {
   max-width: 38.5rem;
  }
`


export const InfoContainer = styled(FlexContainer)``
