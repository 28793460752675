import styled from 'styled-components'
import screen_breakpoint from './screen_breakpoints'

const SectionContainer = styled.div`
  margin: 0 1rem;
  position: relative;
  min-height: .1rem;
  box-sizing: border-box;
  padding: 2rem 2.7rem;

  ${screen_breakpoint.sm} {
    margin: auto;
    margin-top: 1rem;
    max-width: 54rem;
  }

  ${screen_breakpoint.md}{
    padding: 2rem 4rem;
    margin-top: 3rem;
    max-width: 76.8rem;
  }

  ${screen_breakpoint.xl}{
    max-width: 110.4rem;
  }

  ${screen_breakpoint.xxl}{
    max-width: 128rem;
  }
`

export default SectionContainer