/* eslint-disable react/react-in-jsx-scope */
import {SocialIcon} from '../common-components'
import {instagram, facebook} from './svgIcons'


const socialsData = [
    {
        icon: (
            <SocialIcon>
                { instagram }
            </SocialIcon>
        ),
        link: {
            zabkowska: 'https://instagram.com/beer_station_pl?igshid=YmMyMTA2M2Y=',
            lwowska: 'https://instagram.com/beer_station_pl?igshid=YmMyMTA2M2Y=',
            ursynow: 'https://instagram.com/beer_station_pl?igshid=YmMyMTA2M2Y=',
            wolska: 'https://www.instagram.com/beerstationwola?igsh=MWJjY2UxM21nNDNvMQ=='
        },
    },
    {
        icon: (
            <SocialIcon>
                { facebook }
            </SocialIcon>
        ),
        link: {
            zabkowska: 'https://www.facebook.com/BeerStationPl/',
            lwowska: 'https://www.facebook.com/PubBeerStation/',
            ursynow: 'https://www.facebook.com/PubBeerStationUrsynow',
            wolska: 'https://www.facebook.com/profile.php?id=61559262516861'
        },
    },
]

export default socialsData
