const beerCardPageData = {
    PL: [
        {
            id: '0',
            info: 'Tu będą ciekawostki z historii piw :)'
        }
    ],
    RU: [
        {
            id: '0',
            info: 'Тут будут интересные факты с истории пива :)'
        }
    ]
}

export default beerCardPageData
