import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setFullAddress} from '../../store/slices/setLocationSlice'
import {v4 as uuidv4} from 'uuid'
import locations from './localSelector.data'
import * as Styled from './LocalSelectorStyles'

const LocalSelector = ({isDisabled}) => {

    const dispatch = useDispatch()
    const {location} = useSelector(state => state.actualLocation)

    const handlerLocation = (e) => {
        if (isDisabled) return
        const location = e.target.getAttribute('data-local')
        const address = e.target.textContent
        dispatch(setFullAddress({
            location: location, 
            address: address,
        }))
    }

    const local = locations.map(item => (
        <Styled.PlaceWrapper 
            key={uuidv4()}
        >
            <Styled.Place
                data-local={item.data}
                onClick={handlerLocation}
                isActive={item.data === location}
            >
                {item.loc}
            </Styled.Place>
        </Styled.PlaceWrapper>
    ))

    return (
        <Styled.Wrapper>
            {local}
        </Styled.Wrapper>
    )
}

export default LocalSelector
