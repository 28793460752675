import lw_slide_1 from '../../assets/slides/lwowska_1.jpg'
import lw_slide_2 from '../../assets/slides/lwowska_2.jpg'
import lw_slide_3 from '../../assets/slides/lwowska_3.jpg'
// import lw_slide_4 from '../assets/slides/lwowska_4.jpg';
// import lw_slide_5 from '../assets/slides/lwowska_5.jpg';
// import lw_slide_6 from '../assets/slides/lwowska_6.jpg';

import kn_slide_1 from '../../assets/slides/kepna_1.jpg'
import kn_slide_2 from '../../assets/slides/kepna_2.jpg'
import kn_slide_3 from '../../assets/slides/kepna_3.jpg'
import kn_slide_4 from '../../assets/slides/kepna_4.jpg'
import kn_slide_5 from '../../assets/slides/kepna_5.jpg'

import ur_slide_1 from '../../assets/slides/bs_u_1.jpg'
import ur_slide_2 from '../../assets/slides/bs_u_2.jpg'
import ur_slide_3 from '../../assets/slides/bs_u_3.jpg'
import ur_slide_4 from '../../assets/slides/bs_u_4.jpg'
import ur_slide_5 from '../../assets/slides/bs_u_5.jpg'
import ur_slide_6 from '../../assets/slides/bs_u_6.jpg'
import ur_slide_7 from '../../assets/slides/bs_u_7.jpg'

import wolska_1 from '../../assets/slides/wolska_1.jpeg'
import wolska_2 from '../../assets/slides/wolska_2.jpeg'
import wolska_3 from '../../assets/slides/wolska_3.jpeg'
import wolska_4 from '../../assets/slides/wolska_4.jpeg'
import wolska_5 from '../../assets/slides/wolska_5.jpeg'
import wolska_6 from '../../assets/slides/wolska_6.jpeg'
import wolska_7 from '../../assets/slides/wolska_7.jpeg'
import wolska_8 from '../../assets/slides/wolska_8.jpeg'
import wolska_9 from '../../assets/slides/wolska_9.jpeg'
import wolska_10 from '../../assets/slides/wolska_10.jpeg'
import wolska_11 from '../../assets/slides/wolska_11.jpeg'
import wolska_12 from '../../assets/slides/wolska_12.jpeg'
import wolska_13 from '../../assets/slides/wolska_13.jpeg'
import wolska_14 from '../../assets/slides/wolska_14.jpeg'
import wolska_15 from '../../assets/slides/wolska_15.jpeg'
import wolska_16 from '../../assets/slides/wolska_16.jpeg'
import wolska_17 from '../../assets/slides/wolska_17.jpeg'
import wolska_18 from '../../assets/slides/wolska_18.jpeg'

export const galleryData = {
    PL: 'Galeria',
    EN: 'Gallery',
    RU: 'Галерея'
}

export const gallerySliderSettings = {
    id: 'gallery',
    slidesPerView: 1,
    loop: true,
    navigation: {
        nextEl: '.arrowNextGallery',
        prevEl: '.arrowPrevGallery',
    },
    // pagination: { 
    //   clickable: true,
    //   el: '.dots',
    // },
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        991: {
            slidesPerView: 2,
        },
        1104 : {
            slidesPerView: 2,
        },
    }
}

export const gallerySlidesData = {
    zabkowska: [
        {slide: kn_slide_1},
        {slide: kn_slide_2},
        {slide: kn_slide_3},
        {slide: kn_slide_4},
        {slide: kn_slide_5},
    ],

    lwowska: [
        {slide: lw_slide_1},
        {slide: lw_slide_2},
        {slide: lw_slide_3},
    // { slide: lw_slide_4 },
    // { slide: lw_slide_5 },
    // { slide: lw_slide_6 },
    ],

    ursynow: [
        {slide: ur_slide_1},
        {slide: ur_slide_2},
        {slide: ur_slide_3},
        {slide: ur_slide_4},
        {slide: ur_slide_5},
        {slide: ur_slide_6},
        {slide: ur_slide_7},
    ],

    wolska: [
        {slide: wolska_1},
        {slide: wolska_2},
        {slide: wolska_3},
        {slide: wolska_4},
        {slide: wolska_5},
        {slide: wolska_6},
        {slide: wolska_7},
        {slide: wolska_8},
        {slide: wolska_9},
        {slide: wolska_10},
        {slide: wolska_11},
        {slide: wolska_12},
        {slide: wolska_13},
        {slide: wolska_14},
        {slide: wolska_15},
        {slide: wolska_16},
        {slide: wolska_17},
        {slide: wolska_18},
    // { slide: lw_slide_6 },
    ],
}
