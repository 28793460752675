const navigationData = {
    google_info: {
        zabkowska: {
            barAdress: 'Warszawa, ul. Ząbkowska, 5 (Praga-Pn)',
            googleLocation: 'https://maps.app.goo.gl/oRGTji7oeBeHkJR68'
        },
        lwowska: {
            barAdress: 'Warszawa, ul. Lwowska, 17 (Centrum)',
            googleLocation: 'https://goo.gl/maps/dDsVtjJuHJ3BgTit9'
        },
        ursynow: {
            barAdress: 'Warszawa, al. KEN, 47 (Ursynów)',
            googleLocation: 'https://maps.app.goo.gl/tAKzyf1oD2pw2phT9'
        },
        wolska: {
            barAdress: 'Warszawa, ul. Wolska 89 (Wola)',
            googleLocation: 'https://maps.app.goo.gl/ppY4NAgVjtroBoQt8'
        },
    },
    menu: {
        main_page : {
            PL: [
                {
                    linkText: 'Piwo',
                    link: '#beer_section'
                },
                {
                    linkText: 'Galeria',
                    link: '#gallery_section'
                }
            ],
            EN: [
                {
                    linkText: 'Beer',
                    link: '#beer_section'
                },
                {
                    linkText: 'Gallery',
                    link: '#gallery_section'
                }
            ],
            RU: [
                {
                    linkText: 'Пиво',
                    link: '#beer_section'
                },
                {
                    linkText: 'Галерея',
                    link: '#gallery_section'
                }
            ]
        },

        beer_card_page : {
            PL: [
                {
                    linkText: 'Główna',
                    link: '/'
                },
            ],
            EN: [
                {
                    linkText: 'Main',
                    link: '/'
                },
            ],
            RU: [
                {
                    linkText: 'Главная',
                    link: '/'
                },
            ]
        }
    }
}

export default navigationData
