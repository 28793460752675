import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setLanguage} from '../../store/slices/selectLanguageSlice'
import {v4 as uuidv4} from 'uuid'
import {langSwitcherData} from './langSwitcher.data'
import * as Styled from './LangSwitcherStyles'

const LangSwitcher = ({isFromTabs}) => {
    const dispatch = useDispatch()
    const langSwitch = useSelector(state => state.selectLanguage.langSwitch)
    const [isDisplay, setIsDisplay] = useState(false)

    const hadlerOpenMenu = () => {
        setIsDisplay(!isDisplay)
    }
    const handleSwitchLanguage = (e) => {
        const language = e.target.getAttribute('data-lang')
        dispatch(setLanguage(language))
        setIsDisplay(false)
    }
    const languageList = langSwitcherData.map(item => (
        <Styled.LanguageSwicthItem
            key={uuidv4()}
            data-lang={item.id}
            onClick={handleSwitchLanguage}
            isActive={item.id === langSwitch}
        >
            {item.id}
        </Styled.LanguageSwicthItem>
    ))

    return (
        <Styled.Selector
            isDisplay={isDisplay}
            isFromTabs={isFromTabs}
        >
            <Styled.Paragraph 
                isDisplay={isDisplay}
                onClick={hadlerOpenMenu}
            >
                {langSwitch}
                <Styled.Wrapper isDisplay={isDisplay}>
                    {languageList}
                </Styled.Wrapper>
            </Styled.Paragraph>
                    
        </Styled.Selector>
    )
}

export default LangSwitcher
