import styled from 'styled-components'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'
import {SectionContainer, FlexContainer} from '../../styles/StyledElements'
import tel from '../../assets/icons/phone-icon.svg'

export const Section = styled(SectionContainer)``

export const Wrapper = styled(FlexContainer)`
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: .8rem;
`
export const TabsWrapper = styled(FlexContainer)`
    margin-bottom: 2rem;
`
export const Telephone = styled.a`
  position: relative;
  padding-left: 1.6rem;
  text-decoration: none;
  font-size: 1.6rem;
  color: ${({theme}) => theme.colors.red};
  text-shadow: .2rem .4rem .1rem #000;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.5rem;
    height: 1.6rem;
    background: url(${tel});
    background-size: contain;
    transform: translateY(-50%);

    ${screen_breakpoints.sm} {
      width: 2.3rem;
      height: 2.4rem;
    }
  }

  ${screen_breakpoints.xs} {
    font-size: 1.9rem;
  }

  ${screen_breakpoints.md} {
    padding-left: 2.6rem;
    font-size: 2.5rem;
  }
`
export const TelephoneTitle = styled.p`
  font-weight: 200;
  font-size: 1.6rem;

  ${screen_breakpoints.xs} {
    font-size: 1.9rem;
  }

  ${screen_breakpoints.sm} {
    font-size: 2.5rem;
  }
`
export const WorkTimeInfoWrapper = styled(FlexContainer)`
    justify-content: flex-start;
    gap: 1rem;
    font-weight: 200;
`
export const WorkTimeTitle = styled.p`
  font-size: 1.6rem;

  ${screen_breakpoints.xs} {
    font-size: 1.9rem;
  }

  ${screen_breakpoints.sm} {
    font-size: 2.5rem;
  }
`
export const WorkTime = styled.p`
  letter-spacing: 1.1px;
  font-weight: ${({theme}) => theme.fontWeight.font_weightSemiBold};
  font-size: 1.6rem;

  ${screen_breakpoints.xs} {
    font-size: 1.9rem;
  }

  ${screen_breakpoints.sm} {
    font-size: 2.5rem;
  }
`
export const MenuLinkWrapper = styled(FlexContainer)`
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 3rem;
  font-size: 2.6rem;
  font-weight: 200;

  ${screen_breakpoints.md} {
    margin-top: 4rem;
    font-size: 3.6rem;
  }
`
export const LinkTitle = styled.p``
export const MenuLink = styled.a`
  text-decoration: none;
  font-weight: ${({theme}) => theme.fontWeight.font_weightSemiBold};
  color: ${({theme}) => theme.colors.red};
  text-shadow: .2rem .4rem .1rem #000;
`
