import events from '../assets/events/events.svg'
import karaoke from '../assets/events/microfon.svg'
import football from '../assets/events/ball.svg'
import christmas from '../assets/events/merryChristmas.png'

const eventsTypesData = [
    {
        id: '1',
        label: 'Events',
        imagePath: events,
    },
    {
        id: '2',
        label: 'Karaoke',
        imagePath: karaoke,
    },
    {
        id: '3',
        label: 'Football',
        imagePath: football,
    },
    {
        id: '4',
        label: 'MerryChristmas',
        imagePath: christmas,
    },
]

export default eventsTypesData