const colors = {
    black: '#1B1D24',
    white: '#fff',
    white_dark: '#a8a7a7',
    black_yellow: '#2b2409',
    orange: '#ff8a00',
    orange_light: '#d67f43',
    orange_dark: '#b12b00',
    yellow: '#cda500',
    yellow_light: '#b3b0a5',
    red: '#980101',
    grey: '#43413e',
    green: '#00ff18',
    green_dark: '#0f7602',
    brown: '#55290a',
    brown_dark: '#3d1a02',
    brown_light: '#d6aa43',
    gold: '#cfc600',
    silver: '#C0C0C2',
    silver_light: '#e3e3e4',
    // color_blue: '#0028FF',
    // color_white: '#fff',
    // color_white1: '#FBFBFB',
    // color_grayDark: '#506473',
    // color_gray: '#6C869A',
    // color_yellow: '#FFDA50', 
    // color_green: '#3efd13',
}

const fontWeight = {
    font_weightLight: 300,
    font_weightRegular: 400,
    font_weightMedium: 500,
    font_weightSemiBold: 600,
    font_weightBold: 700,
}


export const theme = {colors, fontWeight}

