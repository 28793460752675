const useLocation = (location, street) => {
    let addressData
    switch (street.toLowerCase()) {
    case 'zabkowska':
        addressData = location.zabkowska
        break
    case 'lwowska':
        addressData = location.lwowska
        break
    case 'ursynow':
        addressData = location.ursynow
        break
    case 'wolska':
        addressData = location.wolska
        break
    default: 
        addressData = location.zabkowska
        break
    }

    return addressData
}

export default useLocation
