import {useSelector} from 'react-redux'
import useLocation from '../../hooks/useLocation'
import useLanguage from '../../hooks/useLanguage'
import {LocalSelector, LangSwitcher} from '../../common-components'
import {telTitleTranslate, descriptionData} from './description.data.js'
import * as Styled from './DescriptionStyles'
import { useMemo } from 'react'

const Description = () => {
    const location = useSelector(state => state.actualLocation.location)
    const langSwitch = useSelector(state => state.selectLanguage.langSwitch)
    const addressData = useLocation(descriptionData.loc_info, location)
    const telephoneTitle = useLanguage(telTitleTranslate, langSwitch)

    const timeInfo = useLocation(descriptionData.time_info, location)
    const workTimeInfo = useLanguage(timeInfo, langSwitch)

    const menuLinkData = useMemo(() => {
        if (addressData.menu) {
            return useLanguage(addressData.menu, langSwitch)
        }

        return null
    }, [addressData, langSwitch])


    return (
        <Styled.Section>
            
            <Styled.TabsWrapper>
                <LocalSelector />
                <LangSwitcher isFromTabs />
            </Styled.TabsWrapper>
            <Styled.Wrapper>
                <Styled.TelephoneTitle>
                    {telephoneTitle}
                </Styled.TelephoneTitle>
                <Styled.Telephone href={`tel:+48 ${addressData.tel}`}>
                    {addressData.tel}
                </Styled.Telephone>
            </Styled.Wrapper>

            <Styled.WorkTimeInfoWrapper>
                <Styled.WorkTimeTitle>
                    { workTimeInfo.workTitle }
                </Styled.WorkTimeTitle>
                <Styled.WorkTime>
                    { workTimeInfo.workTime }
                </Styled.WorkTime>
            </Styled.WorkTimeInfoWrapper>

            {(menuLinkData && addressData.menu) && (
                <Styled.MenuLinkWrapper>
                    <Styled.LinkTitle>
                        {menuLinkData.title}
                    </Styled.LinkTitle>
                    <Styled.MenuLink
                        href={addressData.menu.url}
                        target="_blank"
                    >
                        {menuLinkData.urlTitle}
                    </Styled.MenuLink>
                </Styled.MenuLinkWrapper>
            )}
        </Styled.Section>
    )
}

export default Description
