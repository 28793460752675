import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {FlexContainer, ImageContainer} from '../../styles/StyledElements'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'

export const Name = styled.p`
  margin-bottom: .7rem;
  width: 15rem;
  min-height: 2.875rem;
  letter-spacing: 1.1px;
  text-align: center;
  ${({nameRegularSize}) => nameRegularSize
        ? 'font-size: 2.5rem;'
        : 'font-size: 1.7rem; line-height: 2.7rem;'
}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${ screen_breakpoints.sm } {
    width: 12rem;
  }

  ${ screen_breakpoints.xl } {
    width: 16rem;
    font-size: ${({nameRegularSize}) => nameRegularSize
        ? '2.5rem'
        : '2rem'
};
  }
`

export const Slide = styled.div`
  position: relative;
  display: block;
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  padding: 1.5rem 2.6rem;
  width: 100%;
  max-width: 26rem;
  height: 16.7rem;
  border: .1rem solid #6a6a6a;
  border-radius: .5rem;

  ${ screen_breakpoints.sm } {
    max-width: 23rem;
  }

  ${ screen_breakpoints.md } {
    max-width: 26rem;
  }

  ${ screen_breakpoints.lg } {
    max-width: 22.5rem;
  }

  ${ screen_breakpoints.xl } {
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 1.5rem 2rem;
    max-width: 27rem;
  }

  & p {
    color: ${({theme}) => theme.colors.white_dark};
  }
`
export const TopWrapper = styled(FlexContainer)`
  margin-bottom: 1.5rem;
  justify-content: space-around;
`
export const CountryWrapper = styled.div`
  margin-right: .5rem;
`
export const Flag = styled(ImageContainer)`
  width: 3.5rem;
  opacity: .7;
`
export const CardNumber = styled.p`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 200;
  /* color: ${({theme}) => theme.colors.white} !important; */
`
export const BeerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Title = styled.p`
  font-size: 1.9rem;
  margin-bottom: .7rem;
  letter-spacing: 1.1px;
  text-align: center;
`

export const BeerType = styled.p`
  font-size: 1.8rem;
  text-align: center;
  ${({typeRegularSize}) => typeRegularSize
        ? 'font-size: 1.8rem;'
        : 'font-size: 1.3rem;'
}
  letter-spacing: 1.1px;
`
export const BottomWrapper = styled(FlexContainer)`
  padding-top: 1.5rem;
  border-top: .1rem solid ${({theme}) => theme.colors.red};
`
export const CostInfo = styled(FlexContainer)`
  font-size: 1.6rem;
  font-weight: 200;

  ${ screen_breakpoints.xl } {
    font-size: 1.8rem;
  }
`
export const BeerValue = styled.p``
export const BeerCost = styled.p``
export const DateInfo = styled.p`
  position: absolute;
  right: 1.5rem;
  font-size: 1rem;
  font-weight: 200;
  text-shadow: none;
  & > span {
    font-size: 1.1rem;
    color: ${({theme}) => theme.colors.red};
  }
`
