export const descriptionData = {
    loc_info: {
        zabkowska: {
            locationAdress: 'Ząbkowska, 5',
            barAdress: 'Warszawa, ul. Ząbkowska, 5',
            tel: '519 020 211',
            googleLocation: 'https://maps.app.goo.gl/oRGTji7oeBeHkJR68',
            menu: {
                url: 'https://www.beerstation.pl/zabkowska/',
                PL: {
                    title: 'Link do karty menu:',
                    urlTitle: 'Menu'
                },
                EN: {
                    title: 'Link to menu card:',
                    urlTitle: 'Menu'
                },
                RU: {
                    title: 'Ссылка на карту меню:',
                    urlTitle: 'Меню'
                }
            }
        },
        lwowska: {
            locationAdress: 'Lwowska, 17',
            barAdress: 'Warszawa, ul. Lwowska, 17',
            tel: '508 545 956',
            googleLocation: 'https://goo.gl/maps/dDsVtjJuHJ3BgTit9',
            menu: null
        },
        ursynow: {
            locationAdress: 'Al. KEN, 47',
            barAdress: 'Warszawa, al. KEN, 47',
            tel: '509 995 000',
            googleLocation: 'https://goo.gl/maps/dDsVtjJuHJ3BgTit9',
            menu: null
        },
        wolska: {
            locationAdress: 'Wolska, 89',
            barAdress: 'Warszawa, ul. Wolska, 89',
            tel: '501 300 327',
            googleLocation: 'https://maps.app.goo.gl/ppY4NAgVjtroBoQt8',
            menu: {
                url: 'https://www.beerstation.pl/wolska/',
                PL: {
                    title: 'Link do karty menu:',
                    urlTitle: 'Menu'
                },
                EN: {
                    title: 'Link to menu card:',
                    urlTitle: 'Menu'
                },
                RU: {
                    title: 'Ссылка на карту меню:',
                    urlTitle: 'Меню'
                }
            }
        },
    },
    time_info: {
        zabkowska: {
            PL: {
                workTitle: 'Otwarte:',
                workTime: 'pn-nd: 15.00 - 01.00',
            },
            EN: {
                workTitle: 'Open:',
                workTime: 'mon-sun: 15.00 - 01.00',
            },
            RU: {
                workTitle: 'Открыто:',
                workTime: 'пн-вс: 15.00 - 01.00',
            }
        },
        
        lwowska: {
            PL: {
                workTitle: 'Otwarte:',
                workTime: 'wt-nd: 15.00 - 01.00,  pn: nieczynne',
            },
            EN: {
                workTitle: 'Open:',
                workTime: 'tue-sun: 15.00 - 01.00,  mon: closed',
            },
            RU: {
                workTitle: 'Открыто:',
                workTime: 'вт-вс: 15.00 - 01.00,  пн: закрыто',
            }
        },
        
        ursynow: {
            PL: {
                workTitle: 'Otwarte:',
                workTime: 'wt-nd: 14.00 - 01.00,  pn: nieczynne',
            },
            EN: {
                workTitle: 'Open:',
                workTime: 'tue-sun: 14.00 - 01.00,  mon: closed',
            },
            RU: {
                workTitle: 'Открыто:',
                workTime: 'вт-вс: 14.00 - 01.00,  пн: закрыто',
            }
        },

        wolska: {
            PL: {
                workTitle: 'Otwarte:',
                workTime: 'wt-nd: 15.00 - 01.00,  pn: nieczynne',
            },
            EN: {
                workTitle: 'Open:',
                workTime: 'tue-sun: 15.00 - 01.00,  mon: closed',
            },
            RU: {
                workTitle: 'Открыто:',
                workTime: 'вт-вс: 15.00 - 01.00,  пн: закрыто',
            }
        },
    },
    menu_info: {
        zabkowska: null,
        
        lwowska: null,
        
        ursynow: null,

        wolska: {
            url: 'https://www.beerstation.pl/wolska/',
            PL: {
                title: 'Link do karty menu:',
                urlTitle: 'Menu'
            },
            EN: {
                title: 'Link to menu card:',
                urlTitle: 'Menu'
            },
            RU: {
                title: 'Ссылка на карту меню:',
                urlTitle: 'Меню'
            }
        },
    }
}

export const telTitleTranslate = {
    PL: 'Pytania oraz rezerwacje po tel.:',
    EN: 'Questions and reservations by phone:',
    RU: 'Вопросы и резервация по тел.:',
}
