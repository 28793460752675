const screen_breakpoints = {
    xxs: '@media only screen and (min-width: 375px)',
    xs: '@media only screen and (min-width: 425px)',
    xss: '@media only screen and (min-width: 513px)',
    sm: '@media only screen and (min-width: 576px)',
    md: '@media only screen and (min-width: 768px)',
    lg: '@media only screen and (min-width: 991px)',
    xl: '@media only screen and (min-width: 1104px)',
    xxl: '@media only screen and (min-width: 1301px)',
}

export default screen_breakpoints