import styled from 'styled-components'
import {FlexContainer, ImageContainer, SectionTitle} from '../../styles/StyledElements'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'

export const EventCard = styled(FlexContainer)`
    position: relative;
    justify-content: center;
    padding: 1.5rem 2.5rem;
    width: 100%;
    min-height: 10rem;
    gap: 2rem;
    border: .1rem solid #6a6a6a;
    border-radius: .5rem;

    ${screen_breakpoints.sm} {
        width: 49%;
    }

    ${screen_breakpoints.lg} {
        padding: 1.5rem 4rem;
    }

    ${screen_breakpoints.xl} {
        padding: 1.5rem 1.5rem 1.5rem 12.5rem;
    }

    ${({isEventActive}) => isEventActive
        ? ''
        : 'opacity: .4;'
    }
`
export const TitleToday = styled.p`
    position: absolute;
    right: 2.6rem;
    top: .3rem;
    font-size: 1.8rem;
    font-style: italic;
    font-weight: ${({theme}) => theme.fontWeight.font_weightLight};
    color: ${({theme}) => theme.colors.orange};

    ${screen_breakpoints.md} {
        font-size: 2.5rem;
    }
`
export const ImageBox = styled(ImageContainer)`
    position: absolute;
    top: 0.5rem;
    left: 2rem;
    width: 3.5rem;
    height: 3.5rem;

    ${screen_breakpoints.sm} {
        top: 1rem;
        left: 1.5rem;
        width: 2.5rem;
        height: 2.5rem;
    }

    ${screen_breakpoints.md} {
        top: 1.5rem;
        left: 3rem;
        width: 3rem;
        height: 3rem;
    }

    ${screen_breakpoints.xl} {
        top: 50%;
        width: 7rem;
        height: 7rem;
        transform: translateY(-50%);
    }
`
export const Image = styled.img``

export const Content = styled(FlexContainer)`
    flex-direction: column;
`
export const Title = styled.p`
    text-align: center;
    font-size: 2rem;
    font-weight: ${({theme}) => theme.fontWeight.font_weightLight};
    color: ${({theme}) => theme.colors.red};

    ${screen_breakpoints.md} {
        font-size: 3.5rem;
    }
`

export const StartDateWrapper = styled(FlexContainer)`
    gap: .5rem;

    ${screen_breakpoints.lg} {
        gap: 1rem;
    }
`
export const StartDate = styled.p`
    font-weight: 200;
    font-size: 1.5rem;
    color: ${({theme}) => theme.colors.white_dark};

    & > span {
        padding-left: .4rem;
        font-weight: ${({theme}) => theme.fontWeight.font_weightRegular};
        color: ${({theme}) => theme.colors.red};
    }

    ${screen_breakpoints.md} {
        font-size: 2rem;
    }
`

export const Description = styled(StartDate)`
    margin-top: .5rem;
    font-size: 1.6rem;
    line-height: 1.32;
    letter-spacing: 0.12rem;
    color: ${({theme}) => theme.colors.silver};

    ${screen_breakpoints.lg} {
        margin-top: 1rem;
        font-size: 1.8rem;
    }
`

export const UrlWrapper = styled(Description)`
    
`
export const Url = styled.a`
    margin-left: .5rem;
    text-decoration: none;
    color: #00ffd2;
`
