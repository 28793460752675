import styled from 'styled-components'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'
import {FlexContainer} from '../../styles/StyledElements'
import selectorTriangle from '../../assets/icons/triangle.svg'

export const Selector = styled(FlexContainer)`
  position: relative;
  margin-top: 0;
  justify-content: center;
  display: ${({isFromTabs}) => isFromTabs && 'none'};

  ${screen_breakpoints.md} {
    display: ${({isFromTabs}) => isFromTabs ? 'block' : 'none'};
  }
`
export const Wrapper = styled.div(
    ({isDisplay}) => `
    position: absolute;
    top: 5rem;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 7.4rem;
    opacity: 0;
    border-width: 0;
    border-style: solid;
    border-color: #980101;
    border-radius: .4rem;
    background-color: #0e0e0e;
    transition: all .3s ease-in-out;
    ${ isDisplay ? 'opacity: 1; height: fit-content; font-size: 2.6rem; z-index: 10; padding: 1rem 1.6rem;border-width:1px;' 
        : 'opacity: 0;  height: 0; font-size: 0; padding: 0;'};
`
)
export const Paragraph = styled.p(
    ({isDisplay}) => `
    position: relative;
    padding: 1rem 2.5rem .6rem 2.5rem;
    cursor: pointer;
    font-size: 3rem;
    font-weight: 200;
    color: #980101;

    &::after {
      content: '';
      position: absolute;
      right: 0rem;
      top: 50%;
      transform: ${isDisplay ? 'translateY(-50%) rotate(180deg);' 
      : 'translateY(-50%);'}
      width: 2rem;
      height: 2rem;
      background: url(${selectorTriangle}) no-repeat;
      background-size: cover;
      transition: all .2s ease-in-out;

      ${screen_breakpoints.sm} {
        right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    ${screen_breakpoints.sm} {
      padding: 1rem 4rem .6rem 1rem;
      font-size: 3.3rem;
    }
`
)
export const LanguageSwicthItem = styled.p`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.white_dark};
  border-color: ${({isActive}) => isActive && '#980101'};
  text-align: center;
  cursor: pointer;
  color: ${({isActive}) => isActive && '#980101'};
  ${({isDisplay}) => isDisplay ? 'padding: .6rem 1rem;' : 'padding: 0;'}

  &:hover {
    border-bottom: 1px solid ${({theme}) => theme.colors.red};
    color: ${({theme}) => theme.colors.red};
  }
`
