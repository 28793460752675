import styled from 'styled-components'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'
import {FlexContainer} from '../../styles/StyledElements'


export const Wrapper = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
  gap: 1.5rem;
  
  ${screen_breakpoints.xss} {
    justify-content: flex-start;
  }

  ${screen_breakpoints.sm} {
    gap: 3rem;
  }

  ${screen_breakpoints.xl} {
    gap: 6rem;
  }
`

export const PlaceWrapper = styled(FlexContainer)`
  width: 46%;
  justify-content: center;
  
  ${screen_breakpoints.md} {
    width: auto;
  }
`

export const Place = styled.p`
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  color: ${({isActive}) => isActive ? ({theme}) => theme.colors.red : ({theme}) => theme.colors.white_dark};
  border-bottom: ${({isActive}) => isActive ? '1px solid #980101' : 'none'};
  transition: all .3s ease-in-out;

  &:hover {
    color: ${({theme}) => theme.colors.red};
  }

  ${screen_breakpoints.xs} {
    font-size: 2.2rem;
  }

  ${screen_breakpoints.xss} {
    font-size: 2.6rem;
  }

  ${screen_breakpoints.md} {
    font-weight: 200;
    font-size: 2.9rem;
  }
`
