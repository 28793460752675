import styled from 'styled-components'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'
import {FlexContainer, SectionContainer, SectionTitle} from '../../styles/StyledElements'

export const Events = styled(SectionContainer)``
export const Title = styled(SectionTitle)``
export const Content = styled(FlexContainer)`
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 1.7rem;

    ${screen_breakpoints.xl} {
        row-gap: 2rem;
    }
`
