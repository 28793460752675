const translations = {
    PL: {
        eventCard: {
            todayTitle: 'Ju​ż dziś!',
            day: 'Dzień: ',
            startTime: 'Godzina: ',
            infoTitle: 'Więcej info (bilety i inn.): ',
            urlTitle: 'Kliknij tutaj',
        }
    },
    EN: {
        eventCard: {
            todayTitle: 'Today!',
            day: 'Day: ',
            startTime: 'Time: ',
            infoTitle: 'More info (tickets & etc.): ',
            urlTitle: 'Click here',
        }
    },
    RU: {
        eventCard: {
            todayTitle: 'Сегодня!',
            day: 'День: ',
            startTime: 'Время: ',
            infoTitle: 'Больше инф. (билеты и др.): ',
            urlTitle: 'Кликни тут',
        }
    },
}

export default translations
