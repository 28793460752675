import styled from 'styled-components'
import {FlexContainer, ImageContainer} from '../../styles/StyledElements'
import screen_breakpoints from '../../styles/StyledElements/screen_breakpoints'
import mapsIcon from '../../assets/icons/maps-icon.svg'

export const NavigationWrapper = styled(FlexContainer)`
justify-content: flex-end;
gap: 5rem;
  margin-bottom: .8rem;

  ${ screen_breakpoints.sm } {
    margin-bottom: 1.3rem;
  }
`
export const IconsWrapper = styled(FlexContainer)`
  position: relative;
  width: 7rem;

  ${ screen_breakpoints.md } {
    width: 9rem;
  }
`
export const LogoImage = styled(ImageContainer)`
  position: relative;
  margin-bottom: .8rem;
  margin-right: auto;
  width: 8rem;
  border-radius: 50%;
  background-color: #fdfdfdb0; 
  box-shadow: 0 0 1.2rem 0 beige;

  ${ screen_breakpoints.sm } {
    margin-bottom: 0;
    width: 10rem;
  }

  &::before {
    display: none;

    ${ screen_breakpoints.xl } {
      content: 'BeerStation';
      display: block;
      position: absolute;
      top: 45%;
      right: -24.5rem;
      font-family: "Jacques Francois Shadow";
      font-size: 3.8rem;
      transform: translateY(-50%);
      color: ${({theme}) => theme.colors.yellow_light};
    }
    
  }
`
export const AdressInfo = styled.a`
  position: absolute;
  top: 9rem;
  left: 1.5rem;
  display: block;
  width: 30rem;
  font-size: 1.6rem;
  font-weight: ${({theme}) => theme.fontWeight.font_weightLight};
  text-shadow: .3rem .3rem .3rem #000;
  text-decoration: none;
  color: ${({theme}) => theme.colors.red};

  ${ screen_breakpoints.xss } {
    top: 11rem;
  }

  ${screen_breakpoints.xs} {
    font-size: 2rem;
  }

  ${ screen_breakpoints.md } {
    top: 42%;
    left: 14.5rem;
    margin-bottom: 0;
    font-size: 2.3rem;
  }

  ${ screen_breakpoints.xl } {
    top: 7rem;
    left: 13.5rem;
    font-size: 2.1rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: -2.3rem;
    top: 43%;
    width: 1.8rem;
    height: 1.8rem;
    transform: translateY(-50%);
    background-image: url(${ mapsIcon }) ;
    background-size: contain;
  }
`
export const MenuWrapper = styled(FlexContainer)`
  display: none;

  ${({isMainPage}) => isMainPage ? '' : 'display: flex;'}

  ${ screen_breakpoints.md } {
    display: flex;
    gap: 1.6rem;
  }

  ${ screen_breakpoints.xl } {
    font-size: 3.3rem;
  }
`
export const MenuLink = styled.a`
  text-decoration: none;
  font-size: 3.5rem;
  color: ${({theme}) => theme.colors.white_dark};
  font-weight: 200;
  text-shadow: .1rem .2rem .2rem #431d00;
  transition: all .15s ease-in-out;

  ${ screen_breakpoints.md } {
    font-size: 4.5rem;
  }

  &:hover {
    cursor: pointer;
    color: ${({theme}) => theme.colors.red};
    text-shadow: 0.1rem 0.2rem .3rem #431d00;
  }
`
