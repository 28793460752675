const translations = {
    PL: {
        events: {
            title: 'Zaplanowane eventy'
        }
    },
    EN: {
        events: {
            title: 'Scheduled events'
        }
    },
    RU: {
        events: {
            title: 'Планируемые события'
        } 
    },
}

export default translations
